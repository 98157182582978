export default {
  computed: {
    list() {
      return this.$store.state.moduleAcronis.list;
    },
    isLoading() {
      return this.$store.state.moduleAcronis.isLoading;
    },
  },
  methods: {
    fetchList() {
      return this.$store.dispatch('moduleAcronis/fetchList');
    },
  },
};
